window.rwd || (window.rwd = {});

;(function($package) {
    var ScreenSize = {
        SIZES: {
            XSMALL: {name: "xsmall", maxWidth: 361 - 1},
            SMALL:  {name: "small", minWidth: 361, maxWidth: 601 - 1},
            MEDIUM: {name: "medium", minWidth: 601, maxWidth: 961- 1},
            LARGE:  {name: "large", minWidth: 961}
        },
        is: function(sizeObj) {
            return ScreenSize.size.name == sizeObj.name;
        },
        getSize: function(width) {
            var selectedSize = null;
            for (var prop in ScreenSize.SIZES) {
                if (!ScreenSize.SIZES.hasOwnProperty(prop)) {
                    continue;
                }
                var size = ScreenSize.SIZES[prop];
                if (size.maxWidth && !(width <= size.maxWidth)) {
                    continue;
                }
                if (size.minWidth && !(width >= size.minWidth)) {
                    continue;
                }
                selectedSize = size;
                break;
            }
            return selectedSize;
        },
        updateSize: function(width) {
            var oldValue = ScreenSize.size.name;
            ScreenSize.size = ScreenSize.getSize(width);
            $(document).trigger(ScreenSize.RESIZE_EVENT_NAME, {name: ScreenSize.size.name, width: width});
            if (oldValue != ScreenSize.size.name) {
                $(document.body).removeClass("size_" + oldValue).addClass("size_" + ScreenSize.size.name);
                $(document).trigger(ScreenSize.SIZE_CHANGE_EVENT_NAME, {name: ScreenSize.size.name, width: width});
            }
        },
        size: {name: "unknown"},
        SIZE_CHANGE_EVENT_NAME: "screen-size-change",
        RESIZE_EVENT_NAME: "screen-resize"
    };
    $package.ScreenSize = ScreenSize;

    ScreenSize.size = ScreenSize.getSize($(window).width());
    $(document).ready(function() {
        $(document.body).addClass("size_" + ScreenSize.size.name);
    });

    var windowResizeTimeout;
    $(window).on("resize", function() {
        clearTimeout(windowResizeTimeout);
        windowResizeTimeout = setTimeout(onWindowResizeFunction, 200);
    });
    function onWindowResizeFunction() {
        ScreenSize.updateSize($(window).width());
    };

})(rwd);
